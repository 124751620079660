/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
//import { createTodo } from './graphql/mutations'
//import { listTodos } from './graphql/queries'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'

import awsExports from "./aws-exports";


import ReactJson from 'react-json-view';




//import { Tree, TreeNode } from 'react-organizational-chart';



import Tree from 'react-tree-graph';



Amplify.configure(awsExports);

const initialState = { name: '', description: '' }



//const query = "mutation { getAllState }"
const getAllStateTN = /* GraphQL */ `mutation GetAllStateTN($treeName: String!) {
  getAllStateTN(treeName: $treeName)
}
` 


const getPipeTree = /* GraphQL */ `
  query GetPipeTree($id: ID!) {
    getPipeTree(id: $id) {
      id
      title
      description
      status
      tree
      states {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

let sample = {
  body: {  
    name: 'No Data Yet...'
  }
};


const App = () => {
  


  async function fetchF3() {

    try {

      let resolver = true;
      let f3Data = null;
      let allState = null;

      if(resolver) {

        //const f3Data = await API.graphql(graphqlOperation(query))
        const f3Data = await API.graphql(graphqlOperation(
          getAllStateTN,
          {treeName: "nlse"}
        ));
        console.log("f3Data", f3Data)
        allState = JSON.parse(f3Data.data.getAllStateTN)[0]
        //allState = JSON.parse(f3Data.data.getPipeTree)

      

      } else {


        try {
          //let res = await Storage.put("led242.json", "{}", {contentType: "application/json"});
          let res = await Storage.get("led242.json", {download: true});
          console.log("storage", res);

          let body = await res.Body.text();

          console.log("body", body);


          f3Data = body;

          allState = JSON.parse(f3Data);

        } catch (err) {
          console.log("err", err);
        }
      }



      


      console.log("allstate", allState)
      //let item = JSON.parse(items[0].data).body
      
      //let item = allState[0]; // only handling one for now
      let item = allState;

      console.log("item.children.length", item.children.length);


      item = traverse(item)

      

      setF3(item)
      setF4(item)

    } catch (err) { 
      console.log('error fetching f3', err) 
      setF3({name: "Error fetching data.  Sorry."})
      setF4({name: "Error fetching data.  Sorry."})
    }


    // try {
    //   const apiName = 'restisnotgood';
    //   const path = '/items';
    //   const myInit = {
    //     headers: {} // OPTIONAL
    //   };
    
    //   let result =  await API.get(apiName, path, myInit);
    //   console.log("API result", result);

    // } catch (err) {
    //   console.log("API error api get", err);
    // }



  }



  const [ff3, setF3] = useState({name: "Loading data..."})
  const [ff4, setF4] = useState(sample)

  useEffect(() => {
    fetchF3()
  }, [])

  const colorMap = {
    0: "green",
    1: "green",
    2: "yellow",
    3: "yellow",
    4: "yellow",
    5: "green",
    6: "red",
    242: "blue"
  }


  function getColor(i) {
    if(i < 4)
      return "green"
    if(i < 12)
      return "yellow"
    if(i < 20)
      return "orange"
    return "red"
  }

  function traverse(node) {
    

    if(node.type === "repo") {

      node.textProps = {
        fill: "white",
        stroke: "white"
      }
      
    } else {

      //if(node.deploymentStatus) {

      let newColor = getColor(node.lagCount)
      node.textProps = {
        fill: newColor,
        stroke: newColor
        
      }
      // } else {
      //   console.log("no deployment status apparently", node)
      // }
    }

    if(node.children) {
      for (let i=0; i<node.children.length; i++) {
        node.children[i] = traverse(node.children[i]);
      }
    }

    return node;
  } 



  function shouldCollapse( field ) {

    //console.log("SC");
    //console.log(field);

    let name = field.name
    if (name === "commit" || 
      name === "headers" || 
      name === "allJobs" || 
      name === "children" || 
      name === "pendingJobs" || 
      name === "jobList" ||
      name === "successfulJobList" ||
      name === "message" ||
      name === "author" ||
      name === "committer" ||
      name === "parents" ||
      name === "commitList" || 
      name === "deploymentOptionsList" ||
      name === "pullRequestTargets" ||
      name === "approvalRules" ||
      name === "prList") {

      return true;
    }

    return false;

  }


  function getNode(node, nodeKey) {


    if (node.name === nodeKey) {
      return node
    }

    let result = null

    if(node.children) {
      node.children.forEach( child => {
        let childResult = getNode(child, nodeKey);
        if(childResult) {
          console.log("received positive result", node.name, childResult)
          result = childResult
        }
      })
    }
    return result
  }

  function onClick(event, nodeKey) {
    console.log("nodeKey", nodeKey)
    let node = getNode(ff3, nodeKey)
    if(node) {
      setF4(node)
    }
  }

  async function onClick2(event) {
    try {
      console.log(JSON.stringify(event, 0, 1));
      if(event.name === "clickHere") {
        // execute

        const createJobResult = await API.graphql(graphqlOperation(event.value))

        console.log(createJobResult);

        await fetchF3();

      }
    } catch (err) {
      console.log("not clickHere event")
      console.log(err);
    }
    
    // let node = getNode(ff3, nodeKey)
    // if(node) {
    //   setF4(node)
    // }

    // execute


    // refresh

  }



  return (
    <div className="custom-container">
      <AmplifySignOut />
    
      <Tree
        data={ff3}
        height={400}
        width={2000}
        animated
        nodeOffset={400}
        duration="2000"
        
        svgProps={{
          className: 'custom'
        }}
        
        gProps={{
          onClick: onClick
        }}
        />
    
      <ReactJson src={ ff4 } 
        shouldCollapse={shouldCollapse} 
        quotesOnKeys={false}
        displayDataTypes={false}
        enableClipboard={true}

        theme="monokai"

        onSelect={onClick2}
        />
    </div>
  )

}

const styles = {
  container: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

//export default App
export default withAuthenticator(App)